import * as default_data from '../config.js'

async function proposeModelCount(token_count, file=null) {
  var model = 'gpt';
  var models_available = ['gpt-4o', 'gpt35-16k', 'gpt-4o-mini', 'gpt', 'gpt4', 'gpt4-32k', 'gpt4-vision', 'gpt-4o-gov', 'gpt-gov', 'gpt4-gov']

  models_available = models_available.filter((option) => default_data.default_allowed_models.includes(option));

  if(token_count === null) {
    return model;
  }

  if(file !== null && file !== '') {
    if(models_available.includes('gpt-4o-mini')) {
      model = 'gpt-4o-mini';
      return model;
    }
    else if(models_available.includes('gpt-4o')) {
      model = 'gpt-4o';
      return model;
    }
    else if(models_available.includes('gpt-4o-gov')) {
      model = 'gpt-4o-gov';
      return model;
    }
  }

  model = null;
  // loop through available models
  for(var i = 0; i < models_available.length; i++) {
    var token_window = getTokenWindow(models_available[i]);
    if(token_count <= (token_window * 3 / 5)) {
      model = models_available[i];
      break;
    }
  }

  return model;
}

function getTokenWindow(model) {
    if(model === 'gpt' || model === 'openai_gpt') {
      return 4096;
    }
    else if(model === 'gpt-gov') {
      return 16000;
    }
    else if(model === 'cohere') {
      return 4096;
    }
    else if(model === 'groq-70b') {
      return 8192;
    }
    else if(model === 'mpt-7b-chat') {
      return 4096;
    }
    else if(model === 'dolly') {
      return 4096;
    }
    else if(model === 'flan-t5') {
      return 4096;
    }
    else if(model === 'claude2') {
      return 100000;
    }
    else if(model === 'llma2') {
      return 8000;
    }
    else if(model === 'falcon') {
      return 4096;
    }
    else if(model === 'google-bison') {
      return 8192;
    }
    else if(model === 'aws-bedrock-titan') {
      return 8192;
    }
    else if(model === 'google-gemini-pro') {
      return 32000;
    }
    else if(model === 'gpt4') {
      return 8192;
    }
    else if(model === 'gpt4-gov') {
      return 128000;
    }
    else if(model === 'gpt4-32k') {
      return 32768;
    }
    else if(model === 'gpt4-vision') {
      return 128000;
    }
    else if(model === 'gpt-4o') {
      return 128000;
    }
    else if(model === 'gpt-4o-mini') {
      return 128000;
    }
    else if(model === 'gpt-o1-mini') {
      return 128000;
    }
    else if(model === 'gpt-o1') {
      return 128000;
    }
    else if(model === 'gpt-4o-gov') {
      return 128000;
    }
    else if(model === 'dall-e-2') {
      return 1024;
    }
    else if(model === 'dall-e-3') {
      return 1024;
    }
    else if(model === 'gpt35-16k') {
      return 16000;
    }
    return 4096;
}

export { proposeModelCount }