import React, { useState, useCallback, useMemo, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import rehypeSanitize from 'rehype-sanitize';
import { IconButton, Dialog, DialogTitle, DialogContent, Box, Button } from '@mui/material';
import { IoClose } from "react-icons/io5";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { tomorrowNightBright } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { CSVLink } from 'react-csv';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from '@emotion/styled';
import { sanitizeHtml, sanitizeMermaid, checkHref, unEscapeHTML } from '../Utils/text_functions';
import { FiPieChart, FiCopy, FiDownload } from "react-icons/fi";
import * as log from 'loglevel'
import mermaid from 'mermaid';

const Code = styled.div`
  padding: 0;
  border-radius: 0.25rem;
  overflow: hidden;

  & > div {
    margin: 0 !important;
  }

  .fa {
    font-style: normal !important;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;

  button {
    display: flex;
    align-items: center;
    padding: 4px !important;
    margin: 5px;
  } 
`;

const ImagePreview = styled.div`
  text-align: center;

  img {
    max-width: 30rem !important;
    display: block;
  }
`;

const MarkdownRenderer = ({ content, user }) => {
  useEffect(() => {
    mermaid.initialize({ startOnLoad: false, theme: "forest" });
  }, []);

  const extractTableData = useCallback((tableNode) => {
    const rows = [];
    if (!tableNode.children) {
      return rows; 
    }
    try {
      tableNode.children.forEach((child) => {
        if (child.tagName === 'thead' || child.tagName === 'tbody') {
          if (child.children !== null && child.children !== undefined) {          
            child.children.forEach((row) => {
              let cells = [];
              for (let i = 0; i < row.children.length; i++) {
                const cell = row.children[i];
                if (cell.children && cell.children.length > 0) {
                  const firstChild = cell.children[0];
                  if (firstChild.children && firstChild.children.length > 0) {
                    const secondChild = firstChild.children[0];
                    if (secondChild && secondChild.tagName === 'img') {
                      cells.push(secondChild.properties.src);
                    } else if (secondChild) {
                      cells.push(secondChild.value);
                    } else {
                      cells.push(firstChild.value);
                    }
                  } else {
                    cells.push(firstChild.value);
                  }
                }
              }          
              rows.push(cells);
            });
          }
        }
      });
    } catch (e) {
      log.error(e);
    }
    return rows;
  }, []);

  const schema = useMemo(() => ({
    tagNames: ['table', 'thead', 'tbody', 'tr', 'th', 'td', 'ins', 'del', 'p', 'strong', 'em', 'ul', 'ol', 'li', 'a', 'blockquote', 'code', 'pre', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
    attributes: {
      a: ['href', 'title', 'target'],
      ins: [],
      del: []
    }
  }), []);

  const CustomComponent = ({ node, ...props }) => {
    if (React.Children.count(props.children) === 0) return null;

    const children = React.Children.map(props.children, (child) => {
      if (typeof child === 'string') {
        return unEscapeHTML(child);
      }
      return child;
    });

    if (node.tagName === 'p') {
      return (
        <div>
          {children.map((child, index) => {
            if (typeof child === 'string') {
              return <p style={{ display: 'inline' }} key={index} dangerouslySetInnerHTML={{ __html: sanitizeHtml(child).replace(/\n/g, '<br />') }} />;
            }
            // limit child.type to avoid XSS
            const allowedTypes = ['a', 'strong', 'em', 'del', 'ins', 'code', 'pre', 'span', 'img', 'br'];
            if(child.type === 'a') {
              if(child.props.href) {
                if(!checkHref(child.props.href)) {
                  return null;
                }
              }
            }
            else if(child.type === 'img') {
              if(child.props.src) {
                if(!checkHref(child.props.src)) {
                  return null;
                }
              }
            }
            if (child.type && !allowedTypes.includes(child.type)) {
                if(child.props && child.props.node && allowedTypes.includes(child.props.node.tagName)) {
                }
                else {
                  return null;
                }
            }
            return <span style={{ display: 'inline' }} key={index} className="inline-item">{child}</span>;
          })}
        </div>
      );
    }

    return React.createElement(node.tagName, props, children);
  };

  const preprocessContent = (content) => {
    if (!content) return '';
    return content.replace(/```markdown([\s\S]*?)```/g, '$1');
  };

  const preprocessedContent = preprocessContent(content);

  return (
    user === 'me' ? 
      <Box>
          <p style={{ display: 'inline', fontSize: '11pt' }} dangerouslySetInnerHTML={{ __html: preprocessedContent.replace(/\n/g, '<br />') }} />
      </Box> 
    :
    <Box className="markdown-div">
      <ReactMarkdown
        children={preprocessedContent}
        rehypePlugins={[rehypeRaw, rehypeSanitize(schema)]}
        remarkPlugins={[remarkGfm]}
        escapeHTML={false}
        components={{
          code: CodeComponent,
          table({ node, ...props }) {
            const tableData = extractTableData(node);
            return (
              <Box>
                <Box component="table" {...props} className="parsed_table" />
                <Header>
                  <CSVLink className="code_button" data={tableData} filename="export-asksage.csv">
                    <FiDownload alt="Download CSV" size={16} style={{ marginRight: '5px' }} />
                    <span>Download CSV</span>
                  </CSVLink>
                </Header>
              </Box>
            );
          },
          p: CustomComponent
        }}
      />
    </Box>
  );
};

const CodeComponent = ({ node, inline, className, children, ...props }) => {
  const [showMermaidPreview, setShowMermaidPreview] = useState(false);

  function closeModal() {
    setShowMermaidPreview(false);
  }

  const [copied, setCopied] = useState(false);

  const handleCopy = useCallback(() => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }, []);

  const match = /language-(\w+)/.exec(className || '');
  if (!children) return null;

  let code = String(children).replace(/\n$/, '');
  code = unEscapeHTML(code);

  let isMermaid = false
  if(className) {
    isMermaid = className.includes("language-mermaid");
  }

  return !inline && match ? (
    <>
      <Code>
        <SyntaxHighlighter
          style={tomorrowNightBright}
          language={match?.[1] || 'text'}
          PreTag="div"
          {...props}
        >
          {code}
        </SyntaxHighlighter>
        <Header>
          {code.startsWith('<svg') && code.includes('</svg>') && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ minWidth: 0, padding: '2px 4px', display: 'flex', alignItems: 'center' }}
              onClick={() => {
                const blob = new Blob([code], { type: 'image/svg+xml' });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'image.svg';
                a.click();
              }}
            >
              <FiDownload alt="Download SVG" size={16} style={{ marginRight: '5px' }} />
              <span>Download SVG</span>
            </Button>
          )}
          <CopyToClipboard text={code} onCopy={handleCopy}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ minWidth: 0, padding: '2px 4px', display: 'flex', alignItems: 'center' }}
              aria-label="Copy code"
            >
              {copied ? 'Copied!' : <span><FiCopy alt="Copy" size={16} /> Copy</span>}
            </Button>
          </CopyToClipboard>
          <div className="...">
            {isMermaid ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setShowMermaidPreview(true);
                  }}
                >
                    <FiPieChart alt="Open Preview" size={16} style={{marginRight: '5px'}} />
                    Open Mermaid preview
                </Button>
                <Dialog
                  open={showMermaidPreview}
                  setOpen={setShowMermaidPreview}
                  maxWidth="xl"
                  fullWidth            
                  title="Mermaid diagram preview"
                >
                  <DialogTitle id="alert-dialog-title"> 
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      Diagram
                      <IconButton onClick={closeModal}>
                        <IoClose />
                      </IconButton>
                    </Box>
                  </DialogTitle>
                  <DialogContent>
                    <Mermaid content={unEscapeHTML(children?.toString() ?? "")} />
                    </DialogContent>
                  </Dialog>
              </>
            ) : null}
        </div>
        </Header>
      </Code>
      {code.startsWith('<svg') && code.includes('</svg>') && (
        <ImagePreview>
          <img alt="code" src={`data:image/svg+xml;base64,${btoa(code)}`} />
        </ImagePreview>
      )}
    </>
  ) : (
    <code className={className} {...props}>
      {unEscapeHTML(String(children))}
    </code>
  );
};

// A custom component to render a Mermaid diagram given the string.
const Mermaid = ({ content }) => {
  const [diagram, setDiagram] = useState(true);

  useEffect(() => {
    const render = async () => {
      // Generate a random ID for Mermaid to use.
      const id = `mermaid-svg-${Math.round(Math.random() * 10000000)}`;

      // Confirm the diagram is valid before rendering since it could be invalid
      // while streaming, or if the LLM "hallucinates" an invalid diagram.
      try {
        // Try to parse the Mermaid content
        const isValid = await mermaid.parse(content, { suppressErrors: true });
        if (isValid) {
          // If valid, render the diagram
          const { svg } = await mermaid.render(id, content);
          setDiagram(svg);
        } else {
          // If not valid, set diagram to false
          setDiagram(false);
        }
      } catch (error) {
        // Log the error
        log.error('Mermaid parsing error:', error);
        setDiagram(false);
      }
    };
    render();
  }, [content]);

  if (diagram === true) {
    return <p className="...">Rendering diagram...</p>;
  } else if (diagram === false) {
    return <p className="...">Unable to render this diagram.</p>;
  } else {
    return <div style={{backgroundColor: 'white'}} dangerouslySetInnerHTML={{ __html: sanitizeMermaid(diagram, ['svg']) ?? "" }} />;
  }
};

export { MarkdownRenderer };
