import { useTheme, Link } from '@mui/material';
import AskSagePlaylist from '../../AskSagePlaylist';
import * as default_data from '../../../config.js'

export const Training = () => {
  const theme = useTheme();

  return (
    <div style={{clear: 'both'}}>
      <h3 className="modal_header">Documentation</h3>
      <p>Check out our new <Link sx={{textTransform: 'none', color: theme.palette.textColorSecondary}} href="https://docs.asksage.ai" rel="noreferrer" target="_blank">documentation portal (Detailed product documentation, APIs, Plugins, etc.)</Link></p>
      <h3 className="modal_header">Training &amp; Use Case Videos (Watch before Getting Started!)</h3>
      { default_data.default_disable_youtube === true ? 
      'YouTube is disabled on your network, check out our videos at https://www.youtube.com/playlist?list=PLr6nbjG8-gxSBegcnxDGXHE2Zk1bFPid4 on a different device.' :
      <AskSagePlaylist />
      }
    </div>
  )
}