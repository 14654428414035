import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.tooltip.backgroundColor,
    color: theme.palette.tooltip.color,
    dropShadow: '0px 2px 8px rgba(0,0,0,0.32)',
    fontSize: 12,
    padding: theme.spacing(1),
    borderRadius: 6,
    fontWeight: '300',
    border: `1px solid #080429`,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.tooltip.backgroundColor,
  },
}));
