import { Link, useTheme, Paper } from '@mui/material'
import { useState, useEffect } from 'react';
import * as default_data from '../../../config'
import { getToken } from '../../../AuthService'
import { sanitizeHtml } from '../../../Utils/text_functions';

export const Commands = () => {
  const [command_list, setCommandList] = useState("Loading...");
  const [loaded, setLoaded] = useState(false);
  var loaded_static = false;
  const theme = useTheme();

  function loadCommandList() {
    var token = getToken()
    if(token === false) {
      return;
    }

    fetch(default_data.default_chat_service_url + '/get-help', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': `${token}`
      },        
      body: JSON.stringify({
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('An error occurred while getting data.');
      })
      .then(data => {
        if(data.status != 200) {
            return;
        }
        data = data.response.replace(/\n/g, "<br />");

        setCommandList(data);
      })
      .catch(error => {
        console.log(error);
      });
  }

  useEffect(() => {

    if(loaded || loaded_static) {
      return;
    }

    loadCommandList();

    setLoaded(true)
    loaded_static = true;

  }, [])

  return (
    <Paper sx={{ padding: 2, mt: 2 }}>
       <div>
        <h3 className="modal_header">Commands</h3>
          <div className="scroller">
            <strong>To learn more about Prompt Engineering, watch this curated <Link sx={{textTransform: 'none', color: theme.palette.textColorSecondary}} rel="noopener" target="_blank" href="https://www.youtube.com/playlist?list=PLSIv_F9TtLlyyZM9cxyFvBi3kohnidSOW">playlist</Link>!</strong><br /><br />
            <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(command_list) }} />
          </div>
        </div>
    </Paper>
  )
}