import { MenuItem, Select, useTheme } from "@mui/material";

// React code to display a select menu that goes from 0.0 to 1 with 1 decimal increment and default to 0.0
export const SelectLive = ({currentMode, value, onChange, paid, overrideClassname=false}) => {
  const theme = useTheme();

  return (
    <Select 
      value={value}
      onChange={onChange}
      defaultValue={'0'}
      className={ overrideClassname ? "" : "select-menu"}
      size="small"
      sx={{
        backgroundColor: theme.palette.dropdowns.backgroundColor,
      }}
    >
      <MenuItem key="0" value="0" sx={{fontSize: 13}}>
        Off
      </MenuItem>
      { currentMode === 'advanced' ?  
        <MenuItem key="1" value="1" sx={{fontSize: 13}}>
          Live
        </MenuItem> : ''
      }
      {
      (paid == true) ? 
        <MenuItem key="2" value="2" sx={{fontSize: 13}}>
          { currentMode === 'simple' ? 'On' : 'Live+' }
        </MenuItem>
        : ''
      }
    </Select>
  );
};