import { MenuItem, Select, useTheme } from "@mui/material";

// React code to display a select menu that goes from 0.0 to 1 with 1 decimal increment and default to 0.0
export const SelectTemperature = ({value, onChange, fullWidth = false, overrideClassname=false}) => {
  const theme = useTheme();
  const options = Array.from({length: 11}, (_, i) => i * 0.1).map(float => (
    <MenuItem key={float} value={float} sx={{fontSize: 13}}>
      {float === 0 || float === 1 ? float.toFixed(0) : float.toFixed(1)}
    </MenuItem>
  ));
  
  
  return (
    <Select
      value={value}
      onChange={onChange}
      defaultValue={0.0}
      style={{
        width: fullWidth ? '100%' : '65px',
        backgroundColor: theme.palette.dropdowns.backgroundColor,
      }} 
      size="small"
      className={ overrideClassname ? "" : "select-menu"}
    >
      {options}
    </Select>
  );
};